.sideInfo {
  font-size: 1.4rem;
  font-weight: 500;
  padding: 15px 0px 15px 15px;
  border-left: 1px solid var(--color-w-1);
}

.sideInfo > div > p {
  margin-bottom: 15px;
}

.sideInfo p span {
  color: var(--color-d0);
}

.category {
  display: flex;
  align-items: center;
  background-color: var(--color-w-1);
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 15px;
}

.category p {
  text-transform: capitalize;
}

.categoryImg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 15px;
}

.categoryImg img {
  width: 16px;
  height: 16px;
}

.mostSpendingCategory {
  display: flex;
  align-items: center;
  padding-bottom: 15px;
  border-bottom: 1px solid var(--color-w-1);
  margin-bottom: 15px;
}

.mostSpendingCategory img {
  width: 16px;
  height: 18px;
  margin-right: 5px;
}

.categoriesBtn {
  display: block;
  font-size: 1.4rem;
  font-weight: 700;
  text-align: center;
  background-color: transparent;
  color: var(--color-e0);
  padding: 8px 15px;
  border: 2px solid var(--color-e0);
  border-radius: 5px;
  margin: 0px auto;
  transition: 0.5s ease;
}

.categoriesBtn:hover {
  color: var(--color-w0);
}

.sideInfo > p:last-of-type {
  color: var(--color-b1);
}
.sideInfo > p:last-of-type img {
  width: 20px;
  height: 20px;
  filter: brightness(3) saturate(0);
  margin: 0px auto;
}

@media only screen and (max-width: 700px) {
  .sideInfo {
    flex: 0 1 300px;
    padding: 15px;
    border-left: 0px;
    margin-top: 30px;
  }
}

@media only screen and (max-width: 400px) {
  .sideInfo {
    flex: 0 1 1px;
    width: 100%;
  }
}
