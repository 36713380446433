.navBar {
  max-width: fit-content;
  font-size: 1.4rem;
  font-weight: 700;
  color: var(--color-b1);
  padding: 25px 25px 10px 25px;
  border: 2px solid var(--color-m0);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin: 0px auto 15px auto;
}

.navBar button {
  padding: 5px;
  transition: 0.2s;
}

.navBar button:not(:last-of-type) {
  margin-right: 50px;
}

.navBar button[data-active="true"] {
  font-size: 1.6rem;
  color: var(--color-b0);
  translate: 0px 5px;
}

@media only screen and (max-width: 440px) {
  .navBar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 50px 25px 50px;
  }

  .navBar button:not(:last-of-type) {
    margin-right: 0px;
  }

  .navBar button[data-active="true"] {
    translate: 0px 0px;
  }
}
