.main {
  max-width: 885px;
  background-color: var(--color-w0);
  padding: 30px 50px;
  border-radius: 10px;
  margin: 0px auto 30px auto;
}

.topComponents {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 50px;
}

.bottomComponents {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

@media only screen and (max-width: 700px) {
  .bottomComponents {
    justify-content: space-around;
    align-items: center;
  }
}

@media only screen and (max-width: 600px) {
  .main {
    padding: 15px;
  }
}

@media only screen and (max-width: 400px) {
  .bottomComponents {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 15px;
  }
}
