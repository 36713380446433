* {
  padding: 0px;
  border: 0px;
  border-radius: 0px;
  margin: 0px;
  outline: 0px;
  box-sizing: border-box;
  vertical-align: baseline;
}

html {
  font-size: 62.5%;
}

li {
  list-style: none;
}

a {
  display: block;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

input,
button,
select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
}

strong,
em,
dfn,
small,
address {
  font-weight: inherit;
  font-style: inherit;
  font-size: inherit;
}

img {
  display: block;
  width: 100%;
  height: 100%;
}

:root {
  --color-m0: #29d40e;
  --color-m1: #66e54d;
  --color-e0: #2473d4;
  --color-e1: #65a0d7;
  --color-s0: #26541f;
  --color-d0: #d43e0e;
  --color-d1: #e17b56;
  --color-b0: #051a00;
  --color-b1: #b0beac;
  --color-w0: #edf0ec;
  --color-w-1: #d2d3d2;
}

body {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  color: var(--color-b0);
}

@media only screen and (max-width: 600px) {
  html {
    font-size: 54%;
  }
}
