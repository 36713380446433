.modalContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 500;
}

.modal {
  flex: 0 1 65%;
  max-width: 600px;
  background-color: var(--color-w0);
  border-radius: 10px;
  position: relative;
}

.modalType {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  font-weight: 700;
  color: var(--color-w0);
  background-color: var(--color-d0);
  padding: 30px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  transition: 0.3s;
}

.modalType[data-type="expense"] {
  background-color: var(--color-d0);
}

.modalType[data-type="earning"] {
  background-color: var(--color-m0);
}

.modalType button {
  transition: 0.3s;
  position: relative;
}

.modalType button::after {
  content: "";
  display: block;
  width: 0%;
  height: 2px;
  background-color: var(--color-w0);
  transition: 0.3s;
  position: absolute;
  bottom: -5px;
  left: 0px;
}

.modalType[data-type="expense"] button:first-of-type::after {
  width: 100%;
}

.modalType[data-type="earning"] button:last-of-type::after {
  width: 100%;
}

.modalType button:not(:last-of-type) {
  margin-right: 50px;
}

.modalDetails {
  padding: 30px;
}

.modalDetails div {
  width: 100%;
}

.modalDetails div:not(:last-of-type) {
  padding-bottom: 15px;
  border-bottom: 1px solid var(--color-w-1);
  margin-bottom: 15px;
}

.modalDetails label {
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
  font-weight: 500;
  color: var(--color-b1);
  cursor: pointer;
}

.modalDetails input,
.modalDetails select {
  font-size: 1.8rem;
  color: var(--color-b0);
  background-color: transparent;
  padding: 5px;
  margin-top: 15px;
  cursor: pointer;
}

.detailsValue input {
  justify-self: flex-end;
  font-size: 2.4rem;
  text-align: end;
}

.submitBtn {
  display: block;
  font-size: 1.6rem;
  font-weight: 700;
  background-color: transparent;
  padding: 8px 15px;
  border-radius: 5px;
  margin: 30px auto 0px auto;
  transition: 0.3s;
  position: relative;
}

.submitBtn[data-type="expense"] {
  color: var(--color-d0);
  border: 2px solid var(--color-d0);
}

.submitBtn[data-type="earning"] {
  color: var(--color-m0);
  border: 2px solid var(--color-m0);
}

.submitBtn:hover {
  color: var(--color-w0);
}

.submitBtn[data-type="expense"]:hover {
  background-color: var(--color-d1);
}

.submitBtn[data-type="earning"]:hover {
  background-color: var(--color-m1);
}

.modalClose {
  font-size: 1.6rem;
  color: var(--color-w0);
  background-color: var(--color-e0);
  padding: 10px;
  border-top-right-radius: 7px;
  border-bottom-left-radius: 7px;
  position: absolute;
  top: 0px;
  right: 0px;
}

@media only screen and (max-width: 400px) {
  .modal {
    flex: 0 1 95%;
  }
  .modalType {
    padding: 25px;
  }
  .modalDetails {
    padding: 15px;
  }
  .modalDetails div:not(:last-of-type) {
    padding-bottom: 0px;
    margin-bottom: 15px;
  }

  .modalDetails input,
  .modalDetails select {
    width: 100%;
    margin-top: 10px;
  }
}
