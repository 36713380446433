.transactions {
  width: 100%;
  max-height: 1000px;
  margin: 0px auto;
  overflow-y: auto;
}
.transactions::-webkit-scrollbar {
  width: 10px;
}
.transactions::-webkit-scrollbar-track {
  background-color: var(--color-b1);
  border-radius: 5px;
}

.transactions::-webkit-scrollbar-thumb {
  background-color: var(--color-s0);
  border-radius: 5px;
}

.transactionsItem {
  display: grid;
  grid-template-columns: minmax(1px, 40px) 1fr;
  max-width: 500px;
  font-size: 1.4rem;
  background-color: transparent;
  padding: 10px;
  border: 2px solid var(--color-w-1);
  border-radius: 10px;
  margin: 0px auto;
}

.transactionsItem:not(:last-of-type) {
  margin-bottom: 5px;
}

.itemImg {
  grid-column: 1;
  grid-row: 1 / span 2;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 5px;
}

.itemImg img {
  width: 16px;
  height: 16px;
}

.itemName {
  grid-column: 2;
  grid-row: 1;
  margin-right: 5px;
}
.itemValue {
  grid-column: 3;
  grid-row: 1;
  justify-self: end;
  align-self: center;
  font-size: 1.2rem;
  font-weight: 700;
}
.itemValue[data-type="expense"] {
  color: var(--color-d0);
}
.itemValue[data-type="earning"] {
  color: var(--color-m0);
}

.itemCategory, .itemMethod {
  font-size: 1.2rem;
}

.itemMethod {
  text-align: end;
}

.itemDelete {
  grid-column: 4;
  grid-row: 1 / 3;
  justify-self: end;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  margin-left: 15px;
  transition: .3s;
  cursor: pointer;
}

.itemDelete:hover {
  background-color: var(--color-d1);
}
.itemDelete:hover img {
  filter: brightness(0%) invert(1);
}

.itemDelete img {
  width: 18px;
  height: 18px;
  transition: .3s;
}

.transactions > p:last-of-type {
  max-width: fit-content;
  font-size: 1.6rem;
  color: var(--color-b1);
  margin: 0px auto;
}

@media only screen and (max-width: 600px) {
  .itemDelete img {
    width: 15px;
    height: 15px;
  }
}
